<template>
  <div
    class="UserAvatar"
    :class="{
      'UserAvatar--btn': !!($attrs.to || $attrs.href || $listeners.click),
      'UserAvatar--avatar': !($attrs.to || $attrs.href || $listeners.click),
      [`UserAvatar--${size}`]: true,
      'UserAvatar--relative': ownerStar,
    }"
  >
    <v-btn
      v-if="$attrs.to || $attrs.href || $listeners.click"
      class="UserAvatar__circle"
      :class="[`UserAvatar--${size}`, ownerStar ? 'UserAvatar--relative' : null]"
      fab
      :color="colorComputed"
      :width="sizeComputed"
      :height="sizeComputed"
      :elevation="0"
      :style="{ border: borderComputed }"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <img
        v-if="image"
        :src="image"
        :alt="$t('layout.AltUserAvatar', { userLogin: user.userLogin })"
        class="UserAvatar__image"
      >
      <v-icon
        v-else-if="icon"
        :size="iconSize"
        v-text="icon"
      />
      <span
        v-else
        class="UserAvatar__letter font-weight-medium"
        v-text="firstLetter"
      />
    </v-btn>
    <v-avatar
      v-else
      class="UserAvatar__circle"
      :class="[`UserAvatar--${size}`, ownerStar ? 'UserAvatar--relative' : null]"
      :color="colorComputed"
      :size="sizeComputed"
      :style="{ border: borderComputed }"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <img
        v-if="image"
        :src="image"
        :alt="$t('layout.AltUserAvatar', { userLogin: user.userLogin })"
        class="UserAvatar__image"
      >
      <v-icon
        v-else-if="icon"
        color="white"
        :size="iconSize"
        v-text="icon"
      />
      <span
        v-else
        class="UserAvatar__letter white--text font-weight-medium"
        v-text="firstLetter"
      />
    </v-avatar>

    <v-icon
      v-if="ownerStar"
      class="UserAvatar__owner-star-bg"
      color="white"
      size="20"
      v-text="'mdi-star'"
    />
    <v-icon
      v-if="ownerStar"
      class="UserAvatar__owner-star-fg"
      color="success"
      size="10"
      v-text="'mdi-star'"
    />
  </div>
</template>

<script>
import { bgColorForUuid, randomBgColor } from '../helpers'

export default {
  name: 'UserAvatar',

  inheritAttrs: false,

  props: {
    // Display priority:
    // * Image, if provided
    // * Icon, if provided
    // * First letter of user's firstName/lastName/userLogin (in respective priority when some are empty)
    user: { type: Object, required: true },
    image: { type: String, default: null },
    icon: { type: String, default: null },

    size: {
      type: String,
      default: 'md',
      validator: size => ['xs', 'sm', 'md', 'lg', 'xl'].includes(size),
    },
    color: { type: String, default: null },
    ownerStar: { type: Boolean, default: false },
    borderWidth: { type: Number, default: 0 },
    borderColor: { type: String, default: 'white' },
    innerBorder: { type: Boolean, default: false }, // true if border should fit inside specified width
  },

  computed: {
    avatarSize() {
      return {
        xl: 64,
        lg: 40,
        md: 32,
        sm: 24,
        xs: 16,
      }[this.size]
    },
    iconSize() {
      return {
        xl: 24,
        lg: 24,
        md: 24,
        sm: 16,
        xs: 16,
      }[this.size]
    },

    colorComputed() {
      const { color, user: { id: userUuid } } = this
      if (color) return color
      return userUuid ? bgColorForUuid(userUuid) : randomBgColor()
    },

    sizeComputed() {
      const { avatarSize, innerBorder, borderWidth } = this
      return avatarSize + (innerBorder ? 0 : borderWidth * 2)
    },

    borderComputed() {
      const { borderWidth, borderColor } = this
      return borderWidth && `${borderWidth}px solid ${borderColor} !important`
    },

    firstLetter() {
      const { user: { firstName, lastName, userLogin } } = this
      return (firstName?.trim?.() || lastName?.trim?.() || userLogin.trim())
        .slice(0, 1)
        .toLocaleUpperCase()
    },
  },
}
</script>

<style lang="sass" scoped>
.UserAvatar
  width: fit-content
  flex-shrink: 0

  &--relative
    position: relative

  &--avatar &__circle
    display: inline-flex
    align-items: center
    justify-content: center
    user-select: none

  &__image
    height: 100%
    width: 100%
    border-radius: 100%
    object-fit: cover

  &__letter
    font-family: Roboto-Slab, sans-serif

  &__owner-star-bg
    position: absolute
    z-index: 2
    right: -9px
    bottom: -7.5px
    width: 20px
    height: 20px
    pointer-events: none

  &__owner-star-fg
    position: absolute
    z-index: 3
    right: -4px
    bottom: -2.5px
    width: 10px
    height: 10px
    pointer-events: none

  &, ::v-deep.v-btn__content
    font-weight: 400

  &--btn#{&}--xs ::v-deep .v-btn__content
    font-size: 8px
    line-height: normal

  &--btn#{&}--sm ::v-deep .v-btn__content
    font-size: 12px
    line-height: 20px

  &--btn#{&}--md ::v-deep .v-btn__content
    font-size: 20px
    line-height: 24px

  &--btn#{&}--lg ::v-deep .v-btn__content
    font-size: 20px
    line-height: 24px

  &--btn#{&}--xl ::v-deep .v-btn__content
    font-size: 20px
    line-height: 24px

  &--avatar#{&}--xs
    font-size: 8px
    line-height: normal

  &--avatar#{&}--sm
    font-size: 12px
    line-height: 20px

  &--avatar#{&}--md
    font-size: 20px
    line-height: 24px

  &--avatar#{&}--lg
    font-size: 20px
    line-height: 24px

  &--avatar#{&}--xl
    font-size: 20px
    line-height: 24px
</style>
