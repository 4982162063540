<template>
  <ModalLayout
    :content-class="userId ? 'UserDialog UserDialog--edit' : 'UserDialog UserDialog--create'"
    persistent
    padding="24px 48px"
    max-width="479px"
    :value="value"
    no-header
    min-footer-height="64px"
    hide-close-btn
    v-bind="$attrs"
    v-on="$listeners"
    @input="$emit('input', $event)"
    @click:close="$emit('input', false)"
  >
    <div
      v-if="(!userId && !botId) || (user || bot)"
      class="UserDialog__form"
    >
      <h1
        v-if="!userId && !botId"
        class="UserDialog__title mb-6"
        v-text="$t('user.NewUser')"
      />

      <div
        v-else-if="user || bot"
        class="d-flex align-center text-truncate mb-6"
      >
        <UserAvatar
          :user="{ ...user, ...bot, ...form }"
          :icon="isBot ? 'mdi-robot-outline' : null"
          size="lg"
        />
        <div class="text-truncate ml-4">
          <div class="UserDialog__title">
            <template v-if="isBot">
              {{ form.userLogin }}
            </template>
            <template v-else>
              {{ form.firstName }} {{ form.lastName }}
            </template>
          </div>
          <div
            class="text--secondary text-truncate"
            v-text="isBot ? $t('user.Bot') : [
              form.userLogin || user.userLogin,
              form.userEmail || user.userEmail,
            ].filter(x => !!x).join(' ')"
          />
        </div>
      </div>

      <AppSelect
        v-if="!userId && !botId"
        v-model="isBot"
        name="user-type"
        :items="[
          { value: true, text: $t('user.Bot'), icon: 'mdi-robot-outline' },
          { value: false, text: $t('user.User'), icon: 'mdi-account-outline' },
        ]"
        filled
        :label="$t('user.Type')"
        :prepend-inner-icon="{
          true: 'mdi-robot-outline',
          false: 'mdi-account-outline',
        }[isBot]"
      >
        <template #item="{ item: type }">
          <v-icon
            class="mr-2"
            v-text="type.icon"
          />
          {{ type.text }}
        </template>
      </AppSelect>

      <template v-if="!passwordChangeRequired">
        <AppTextField
          v-if="!isBot"
          key="first-name"
          v-model="form.firstName"
          :label="$t('user.FirstName')"
          filled
          type="text"
          name="first-name"
          maxlength="128"
          autocomplete="given-name"
          :autofocus="!userId && !isBot"
          :error-messages="getErrors('firstName')"
          :disabled="saving || !currentUser || (authType === AUTH.LDAP && !currentUser.isAdmin)"
        />

        <AppTextField
          v-if="!isBot"
          key="last-name"
          v-model="form.lastName"
          :label="$t('user.LastName')"
          filled
          type="text"
          name="last-name"
          maxlength="128"
          autocomplete="family-name"
          :error-messages="getErrors('lastName')"
          :disabled="saving || !currentUser || (authType === AUTH.LDAP && !currentUser.isAdmin)"
        />

        <AppTextField
          key="login"
          v-model="form.userLogin"
          :label="isBot ? $t('user.Name') : $t('user.Login')"
          filled
          type="text"
          name="login"
          required
          autocomplete="nickname"
          :readonly="!!userId || !!(user && user.userType === USER_TYPE.LDAP) || !!botId"
          :messages="isBot && !botId ? $t('user.YouWillNotBeAbleToChangeIt') : null"
          :error-messages="getErrors('userLogin')"
          :disabled="saving || !currentUser || !currentUser.isAdmin"
        />

        <AppTextField
          v-if="!isBot"
          key="email"
          v-model="form.userEmail"
          :label="$t('user.Email')"
          filled
          type="email"
          name="email"
          autocomplete="email"
          :error-messages="getErrors('userEmail')"
          :disabled="saving || !currentUser || !currentUser.isAdmin"
        />
      </template>

      <AppTextField
        v-if="!isBot && (!userId || user.userType === USER_TYPE.LOCAL)"
        key="password"
        v-model="form.userPassword"
        :label="$t('user.Password')"
        filled
        :type="previewPassword ? 'text': 'password'"
        name="password"
        autocomplete="new-password"
        maxlength="128"
        :required="!userId"
        :error-messages="getErrors('userPassword')"
        :disabled="saving || !currentUser || (authType === AUTH.LDAP && !currentUser.isAdmin)"
      >
        <template #append>
          <v-btn
            icon
            @click="previewPassword = !previewPassword"
          >
            <v-icon
              v-text="previewPassword ? '$eye-close' : '$eye'"
            />
          </v-btn>
        </template>
      </AppTextField>

      <AppTextField
        v-if="!isBot && (!user || user.userType === USER_TYPE.LOCAL)"
        key="password-confirm"
        v-model="form.userPasswordConfirm"
        :label="$t('user.PasswordConfirm')"
        filled
        :type="previewPasswordConfirm ? 'text': 'password'"
        name="passwordConfirm"
        autocomplete="new-password"
        maxlength="128"
        :required="!userId"
        :error-messages="getErrors('userPasswordConfirm')"
        :disabled="saving || !currentUser || (authType === AUTH.LDAP && !currentUser.isAdmin)"
      >
        <template #append>
          <v-btn
            icon
            @click="previewPasswordConfirm = !previewPasswordConfirm"
          >
            <v-icon
              v-text="previewPasswordConfirm ? '$eye-close' : '$eye'"
            />
          </v-btn>
        </template>
      </AppTextField>

      <template v-if="!passwordChangeRequired">
        <AppSelect
          key="role"
          v-model="form.role"
          :label="$t('user.Role')"
          filled
          type="text"
          name="role"
          required
          autocomplete="off"
          :items="userRoles"
          item-value="name"
          item-text="displayName"
          :readonly="!!(user && user.userType === USER_TYPE.LDAP) || !!botId"
          :messages="isBot && !botId ? $t('user.YouWillNotBeAbleToChangeIt') : null"
          :error-messages="[]"
          :disabled="saving || !currentUser || !currentUser.isAdmin"
          :prepend-inner-icon="selectedRoleIcon"
        >
          <template #item="{ item: role }">
            <v-icon
              class="mr-2"
              v-text="role.icon"
            />
            {{ $t(`user.${role.displayName}`) }}
          </template>
        </AppSelect>

        <AppSelect
          v-if="!isBot && !!userId && currentUser && currentUser.isAdmin"
          key="state"
          v-model="form.state"
          :label="$t('user.Status')"
          filled
          type="text"
          name="state"
          autocomplete="off"
          :items="USER_STATE_OPTIONS"
          item-value="value"
          item-text="label"
          :item-disabled="getStateIsDisabled"
          :error-messages="[]"
          :disabled="saving"
          :prepend-inner-icon="(USER_STATE[form.state] || USER_STATE.ACTIVE).icon"
          :style="{ '--app-prepend-inner-icon-color': resolveColor((USER_STATE[form.state] || USER_STATE.ACTIVE).iconColor) }"
        >
          <template #item="{ item: state }">
            <v-icon
              class="mr-2"
              :color="state.iconColor"
              v-text="state.icon"
            />
            {{ $t(state.label) }}
          </template>
          <template #selection="{ item: state }">
            {{ $t(state.label) }}
          </template>
        </AppSelect>

        <AppSelect
          v-if="!!userId && profile && appLanguages.length > 1"
          key="language"
          v-model="form.language"
          :label="$t('user.Language')"
          type="text"
          name="language"
          required
          filled
          autocomplete="off"
          :items="appLanguages"
          item-value="name"
          item-text="displayName"
          :error-messages="[]"
          :disabled="saving || !currentUser"
        />

        <!-- Block to turn on/off MFA and/or reset code-->
        <div
          v-if="!!(
            userId &&
            user &&
            currentUser &&
            systemSettings &&
            (profile || currentUser.isAdmin)
          )"
          class="d-flex align-center my-6"
        >
          <v-checkbox
            v-model="form.mfa"
            :disabled="!user || saving || systemSettings.mfaRequired || (!form.mfa && currentUser.id !== userId)"
            name="mfa-on"
            hide-details
            :label="$t('user.SignInWithCodeM')"
            class="ma-0 pa-0"
          />
          <v-btn
            v-if="user.mfaConfirmed && (profile || currentUser.isAdmin || currentUser.id === userId)"
            text
            tile
            color="primary"
            height="24"
            class="px-1 ml-auto"
            @click="resetMfa"
          >
            {{ $t('user.ResetCode') }}
          </v-btn>
        </div>

        <div
          v-if="!isBot"
          class="UserDialog__groups"
        >
          <div class="d-flex align-center">
            <CommonAutocomplete
              v-if="showGroupsQuery"
              ref="groupSelect"
              :value="groupSelectorValue"
              outlined
              dense
              margins-with-hidden-details="mb-0"
              :items="userGroupItems"
              item-value="id"
              item-text="name"
              autofocus
              :placeholder="$t('userGroup.GroupName')"
              prepend-inner-icon="mdi-magnify"
              @change="onGroupSelected"
            />
            <div
              v-else
              class="UserDialog__subtitle flex-grow-1 flex-shrink-1"
              @click="currentUser && currentUser.isAdmin ? (showGroupsQuery = true) : null"
              v-text="$t('user.GroupMembership')"
            />
            <CommonIconButton
              v-if="currentUser && currentUser.isAdmin"
              :icon="showGroupsQuery ? 'mdi-close-circle-outline' : 'mdi-plus-circle-outline'"
              color="primary"
              class="ml-6"
              @click="showGroupsQuery = !showGroupsQuery"
            />
          </div>

          <v-list
            v-if="selectedUserGroups.length"
            ref="groupList"
            dense
            class="py-3"
          >
            <v-list-item
              v-for="group in selectedUserGroups"
              :key="group.id"
              color="error"
              :ripple="currentUser && currentUser.isAdmin ? { class: 'error--text' } : false"
              class="UserDialog__list-item px-1"
              v-on="getSelectedGroupListeners(group.id)"
            >
              {{ group.name }}
              <v-icon
                v-if="currentUser && currentUser.isAdmin"
                class="UserDialog__hover-icon ml-auto"
                color="error"
                v-text="'mdi-minus-circle-outline'"
              />
            </v-list-item>
          </v-list>
        </div>
      </template>

      <div
        v-if="isBot && botId"
        ref="regenerateContainer"
        class="mt-6"
        style="position: relative; height: 40px"
      >
        <v-fade-transition>
          <v-btn
            v-if="!showRegenerateTokenConfirmation"
            key="regenerate-btn"
            :disabled="saving"
            :loading="saving"
            text
            plain
            color="primary"
            absolute
            style="top: 0; left: 0"
            class="px-1"
            @click="showRegenerateTokenConfirmation = true"
          >
            {{ $t('user.RefreshToken') }}
            <v-icon
              class="ml-1"
              v-text="'mdi-refresh'"
            />
          </v-btn>
          <v-card
            v-else
            key="regenerate-confirmation"
            v-click-outside="{
              handler: () => (showRegenerateTokenConfirmation = false),
              include: () => [$refs.regenerateContainer],
            }"
            class="UserDialog__confirmation-card"
            outlined
          >
            <span
              class="mr-6 font-weight-medium"
              v-text="$t('user.DoYuConfirmRefreshQ')"
            />
            <v-spacer />
            <v-btn
              text
              tile
              color="error"
              class="px-1"
              :min-width="10"
              @click="showRegenerateTokenConfirmation = false; form.regenerateToken = true; $v.$touch(); validateAndSubmit()"
            >
              {{ $t('layout.Yes') }}
            </v-btn>
            <v-divider
              vertical
              class="mx-2"
            />
            <v-btn
              text
              tile
              color="primary"
              class="px-1"
              :min-width="10"
              @click="showRegenerateTokenConfirmation = false"
            >
              {{ $t('layout.Cancel') }}
            </v-btn>
          </v-card>
        </v-fade-transition>
      </div>

      <div v-if="!isBot && passwordChangeRequired">
        <v-icon
          :size="24"
          color="#FFC480"
          v-text="'mdi-alert-outline'"
        />
        <span class="ml-2 mr-2">{{ $t('user.UpdatePassword') }}</span>
      </div>
    </div>

    <template #footer>
      <div
        v-if="isBot && botId"
        ref="deleteContainer"
        class="mr-2"
        style="position: relative; height: 40px"
      >
        <v-fade-transition>
          <v-btn
            v-if="!showDeleteConfirmation"
            key="delete-btn"
            outlined
            color="error"
            :loading="deleting"
            min-width="40"
            width="40"
            absolute
            style="top: 0; left: 0"
            @click="showDeleteConfirmation = true"
          >
            <v-icon v-text="'mdi-delete-outline'" />
          </v-btn>
          <v-card
            v-else
            key="delete-confirmation"
            v-click-outside="{
              handler: () => (showDeleteConfirmation = false),
              include: () => [$refs.deleteContainer],
            }"
            class="UserDialog__confirmation-card"
            outlined
          >
            <span
              class="mr-6 font-weight-medium"
              v-text="$t('layout.DeleteItQ')"
            />
            <v-spacer />
            <v-btn
              text
              tile
              color="error"
              class="px-1"
              :min-width="10"
              @click="showDeleteConfirmation = false; deleteBot()"
            >
              {{ $t('layout.Yes') }}
            </v-btn>
            <v-divider
              vertical
              class="mx-2"
            />
            <v-btn
              text
              tile
              color="primary"
              class="px-1"
              :min-width="10"
              @click="showDeleteConfirmation = false"
            >
              {{ $t('layout.No') }}
            </v-btn>
          </v-card>
        </v-fade-transition>
      </div>
      <v-spacer />
      <v-btn
        :disabled="saving || !currentUser || (!isBot && authType === AUTH.LDAP && !currentUser.isAdmin)"
        :loading="saving"
        depressed
        color="primary"
        @click="validateAndSubmit"
      >
        {{ userId || botId ? $t('user.Save') : $t('user.Create') }}
      </v-btn>
      <v-btn
        :disabled="saving"
        outlined
        color="primary"
        class="ml-2"
        @click.prevent.stop="$emit('input', false)"
      >
        {{ $t('layout.Cancel') }}
      </v-btn>

      <MfaSetupDialog
        :value="mfaDialog"
        v-bind="mfaDialogState"
        @input="$event ? (mfaDialog = true) : onMfaDialogRejection()"
        @success="onMfaDialogSuccess"
      />
    </template>
  </ModalLayout>
</template>

<script>
import { helpers, ModalLayout } from '@hexway/shared-front'
import * as R from 'ramda'
import { required, maxLength, minLength, email, sameAs } from 'vuelidate/lib/validators'

import i18n from '@/i18n'
import { USER_STATE, USER_TYPE, AUTH } from '@/constants'
import { reportError, replaceRoute, removeInPlace } from '@/helpers'

import UserGroup from '@/store/orm/userGroup'
import UserRole from '@/store/orm/userRole'

import MfaSetupDialog from './MfaSetupDialog'
import UserAvatar from './UserAvatar'

const validationErrors = {
  'firstName-maxLength': i18n.t('user.validationErrors.FirstNameMaxLength'),
  'lastName-maxLength': i18n.t('user.validationErrors.LastNameMaxLength'),
  'userLogin-required': i18n.t('user.validationErrors.UserLoginRequired'),
  'userEmail-email': i18n.t('user.validationErrors.UserEmail'),
  'userPassword-required': i18n.t('user.validationErrors.UserPasswordRequired'),
  'userPassword-minLength': i18n.t('user.validationErrors.UserPasswordMinLength'),
  'userPassword-maxLength': i18n.t('user.validationErrors.UserPasswordMaxLength'),
  'userPasswordConfirm-sameAsPassword': i18n.t('user.validationErrors.UserPasswordConfirm'),
}

const formValidation = (isNew, isBot) => {
  const validation = { userLogin: { required } }

  if (!isBot) {
    const userPassword = { minLength: minLength(6), maxLength: maxLength(128) }
    if (isNew && !isBot) userPassword.required = required

    Object.assign(validation, {
      firstName: { maxLength: maxLength(64) },
      lastName: { maxLength: maxLength(64) },
      userEmail: { email },
      userPassword,
      userPasswordConfirm: { sameAsPassword: sameAs('userPassword') },
    })
  }

  return validation
}

export default {
  name: 'UserDialog',

  components: {
    MfaSetupDialog,
    ModalLayout,
    UserAvatar,
  },

  inheritAttrs: false,

  props: {
    value: { type: Boolean, default: true }, // isOpen?
    userId: { type: String, default: null },
    botId: { type: String, default: null },
    passwordChangeRequired: { type: Boolean, default: false },
    profile: { type: Boolean, default: false },
    hideCreateBot: { type: Boolean, default: false },
  },

  data() {
    return {
      USER_STATE,
      USER_TYPE,
      AUTH,

      USER_STATE_OPTIONS: [
        USER_STATE.ACTIVE,
        USER_STATE.PENDING,
        USER_STATE.BLOCKED,
      ],

      isBot: false,
      form: null,
      previewPassword: false,
      previewPasswordConfirm: false,
      showGroupsQuery: false,
      groupSelectorValue: null,
      showRegenerateTokenConfirmation: false,
      showDeleteConfirmation: false,

      mfaDialog: false,
      mfaDialogState: {
        userId: null,
        otpauthUrl: '',
        base32: '',
      },

      saving: false,
      deleting: false,
    }
  },

  validations() {
    return {
      form: formValidation(!this.userId && !this.botId, this.isBot),
    }
  },

  computed: {
    user() {
      const { $store, userId } = this
      return userId ? $store.getters['user/get'](userId) : null
    },
    bot() {
      const { $store, botId } = this
      return botId ? $store.getters['botUser/get'](botId) : null
    },

    currentUser() {
      return this.$store.getters['user/current'] || null
    },

    systemSettings() {
      return this.$store.state.service.systemSettings
    },

    userRoles() {
      return UserRole.getOrderedQuery().all()
    },

    firstError() {
      const fields = Object.keys(formValidation(!this.userId))
      for (const field of fields) {
        const errors = this.getErrors(field)
        if (errors.length) return errors[0]
      }
      return null
    },

    authType() { return this.$store.state.service.serverInfo?.authenticationMethod },

    appLanguages() {
      return this.$store.state.ext.environment?.product?.languages || []
    },

    selectedRoleIcon() {
      return this.form.role && UserRole.find(this.form.role)?.icon
    },

    userGroups() { return UserGroup.all() },
    userGroupItems() {
      return R.sortWith([
        R.ascend(R.prop('name')),
      ], this.userGroups.filter(g => !(this.form.userGroups || []).includes(g.id)))
    },
    selectedUserGroups() {
      return R.sortWith([
        R.ascend(R.prop('name')),
      ], UserGroup.query().whereIdIn(this.form.userGroups).all())
    },

    groupsToAdd() {
      const { isBot, userId, user, form } = this
      if (isBot) return []
      if (userId) {
        if (!user) return null
        const oldGroups = new Set(user.userGroups.map(R.prop('id')))
        return form.userGroups.filter(gId => !oldGroups.has(gId))
      }
      return form.userGroups
    },
    groupsToRemove() {
      const { isBot, userId, user, form } = this
      if (isBot || !userId) return []
      if (!user) return null
      const oldGroups = user.userGroups.map(R.prop('id'))
      return oldGroups.filter(gId => !form.userGroups.includes(gId))
    },
  },

  watch: {
    value: {
      handler(isOpen) {
        this.$store.commit('defaultLayout/setPushAjaxLoaderTop', isOpen)
        this.isBot = !!this.botId
        if (isOpen) {
          UserRole.dispatch('$get')
          this.initForm()
        } else {
          // replaceRoute(this.$router, {
          //   ...this.$route,
          //   query: { ...R.omit(['changePassword'], this.$route.query) },
          // })
        }
      },
      immediate: true,
    },

    user: {
      handler: 'initForm',
      immediate: true,
    },
    bot: {
      handler: 'initForm',
      immediate: true,
    },

    userId: [
      {
        handler: 'fetchUserDetails',
        immediate: true,
      },
      {
        handler(userId) {
          if (userId) this.isBot = false
        },
        immediate: true,
      },
    ],

    botId: {
      handler(botId) { if (botId) this.isBot = true },
      immediate: true,
    },
  },

  async created() {
    this.$mfaPromiseResolve = null
    this.$mfaPromiseReject = null
    await Promise.all([
      this.$store.dispatch('service/getServerInfo'),
      !this.systemSettings && await this.$store.dispatch('service/getSystemSettings'),
    ])
  },

  methods: {
    removeInPlace,
    resolveColor(c) { return helpers.vuetifyColorToHex(this.$vuetify, c) },

    async fetchUserDetails(userId) {
      if (!userId) return
      await this.$store.dispatch('user/getDetails', { userId })
    },

    initForm() {
      const { user, bot, isBot } = this
      this.form = user
        ? R.pipe(
          R.clone,
          R.over(R.lensProp('firstName'), R.defaultTo('')),
          R.over(R.lensProp('lastName'), R.defaultTo('')),
          R.over(R.lensProp('userEmail'), R.defaultTo('')),
          R.over(R.lensProp('role'), R.defaultTo(UserRole.DEFAULT_ROLE)),
          R.over(R.lensProp('language'), R.defaultTo(this.$i18n?.locale || 'en')), // $i18n is undefined in tests, difficult to mock
          R.ifElse(
            R.propEq('mfaConfirmed', true),
            R.assoc('mfa', true),
            R.assoc('mfa', false),
          ),
          R.assoc('userPassword', ''),
          R.assoc('userPasswordConfirm', ''),
          R.over(R.lensProp('userGroups'), R.pipe(R.defaultTo([]), R.map(R.prop('id')))),
          R.omit(['id', 'mfaConfirmed', 'isAdmin', 'permissions']),
        )(user)
        : bot
          ? R.pipe(
            R.pick(['userLogin', 'role']),
            R.over(R.lensProp('userLogin'), R.defaultTo('')),
            R.over(R.lensProp('role'), R.defaultTo(UserRole.DEFAULT_ROLE)),
            R.assoc('regenerateToken', false),
          )(bot)
          : this.getDefaultForm(isBot)
      this.previewPassword = false
      this.previewPasswordConfirm = false
      this.groupSelectorValue = null
      this.showGroupsQuery = false
      this.$v.$reset()
    },

    getErrors(field) {
      const v = this.$v.form[field]

      if (!v?.$dirty) return []
      return Object.entries(v)
        .filter(([k, _]) => !k.startsWith('$'))
        .filter(([_, v]) => !v)
        .map(([k, _]) =>
          validationErrors[`${field}-${k}`] ||
          `${this.$t('user.validationErrors.ValidationError')} ${field} ${k}`)
    },

    async validateAndSubmit() {
      this.$v.$touch() // highlight errors
      await this.$nextTick()
      this.$v.$touch() // highlight errors

      const { $store, form, userId, currentUser, groupsToAdd, groupsToRemove } = this

      if (this.$v.$error) {
        return this.$store.commit('$snackbar/setMessage', {
          message: this.firstError || this.$t('user.fixErrorsM'),
        })
      }

      if (this.isBot) return this.saveBot()

      let saved = null
      if (currentUser?.isAdmin) {
        saved = userId
          ? $store.dispatch('user/update', {
            user: {
              id: userId,
              ...R.pipe(
                R.when(() => currentUser.id === userId, R.omit(['state'])),
                R.omit(['userType', 'mfa', 'userPasswordConfirm', 'passwordChangeRequired', 'language', 'userGroups']),
                R.when(form => !form.userPassword.trim(), R.omit(['userPassword'])),
                R.when(form => !form.userEmail.trim(), R.assoc('userEmail', null)),
              )(form),
            },
          })
          : $store.dispatch('user/register', {
            form: R.pipe(
              R.omit(['isAdmin', 'state', 'userType', 'mfa', 'userPasswordConfirm', 'passwordChangeRequired', 'language', 'userGroups']),
              R.when(form => !form.userEmail.trim(), R.assoc('userEmail', null)),
            )(form),
            signIn: false,
          })
            .then((userId) => {
              if (form.isAdmin || form.state !== USER_STATE.ACTIVE.value) {
                return $store.dispatch('user/update', {
                  user: {
                    id: userId,
                    ...R.pick(['isAdmin', 'state'], form),
                  },
                })
              }
              return userId
            })
      } else {
        saved = $store.dispatch('user/update', {
          user: {
            id: userId,
            ...R.pipe(
              R.when(() => currentUser.id === userId, R.omit(['state'])),
              R.omit(['userType', 'mfa', 'isAdmin', 'userEmail', 'userPasswordConfirm', 'passwordChangeRequired', 'role', 'language', 'userGroups']),
              R.when(form => !form.userPassword.trim(), R.omit(['userPassword'])),
            )(form),
          },
        }).then((userId) => {
          if (form.isAdmin || form.state !== USER_STATE.ACTIVE.value) {
            return $store.dispatch('user/update', {
              user: {
                id: userId,
                ...R.pick(['isAdmin', 'state'], form),
              },
            })
          }
          return userId
        })
      }

      saved = saved.then(userId => {
        const extraActions = []
        if (groupsToAdd?.length) {
          extraActions.push($store.dispatch('user/addToGroups', {
            userId,
            userGroupIds: groupsToAdd,
          }))
        }
        if (groupsToRemove?.length) {
          extraActions.push($store.dispatch('user/removeFromGroups', {
            userId,
            userGroupIds: groupsToRemove,
          }))
        }
        return Promise.all(extraActions).then(() => userId)
      })

      this.saving = true
      try {
        const createdOrUpdatedUserId = await saved
        await this.processMfaSetup(createdOrUpdatedUserId)
        if (this.profile) {
          await $store.dispatch('ext/getLanguage', { locale: form.language })
        }
        await this.onSuccess()
      } catch (e) {
        reportError(e).catch(() => { /* pass */ })
      } finally {
        this.saving = false
      }
    },

    async processMfaSetup(userId) {
      const { $store, form } = this
      const user = this.user || $store.getters['user/get'](userId)

      // We have to set up MFA when existing user without confirmed MFA setup &&
      // MFA checkbox has been ticked
      const shouldEnableForExistingUser =
        !!userId &&
        !user?.mfaConfirmed &&
        form.mfa

      const shouldDisable =
        !!userId &&
        user.mfaConfirmed &&
        !form.mfa

      if (shouldEnableForExistingUser) {
        return this._enableMfa(userId)
      } else if (shouldDisable) {
        return this._disableMfa(userId)
      }
    },

    async _enableMfa(userId) {
      const { otpauthUrl, base32 } =
          await this.$store.dispatch('user/enableMfa', { userId })

      return await new Promise((resolve, reject) => {
        this.mfaDialogState = { userId, otpauthUrl, base32 }
        this.mfaDialog = true
        this.$mfaPromiseResolve = resolve
        this.$mfaPromiseReject = reject
      })
    },

    _disableMfa(userId) {
      return this.$store.dispatch('user/disableMfa', { userId })
    },

    async resetMfa() {
      if (!await this.$store.dispatch('confirm/openDialog', {
        title: this.$t('user.ResetSecurityCodeQ'),
        subtitle: this.$t('user.UseIfAccessLostM'),
      })) return
      await this._disableMfa(this.userId)
      if (this.profile) return this._enableMfa(this.userId)
    },

    async saveBot() {
      const { $store, form, botId } = this

      const saved = botId
        ? form.regenerateToken && $store.dispatch('botUser/regenerateToken', { botId })
        : $store.dispatch('botUser/register', {
          bot: {
            role: form.role,
            userLogin: form.userLogin,
          },
        })

      this.saving = true
      try {
        await saved
        await this.onSuccess(true)
      } catch (e) {
        reportError(e).catch(() => { /* pass  */ })
      } finally {
        this.saving = false
      }
    },

    onMfaDialogRejection() {
      this.mfaDialog = false
      this.$mfaPromiseReject(new Error(this.$t('user.MfaConfigurationCancelled')))
      // this.$store.commit('$snackbar/setMessage', { message: 'MFA configuration cancelled' })
    },

    onMfaDialogSuccess() {
      this.$mfaPromiseResolve(this.mfaDialogState)
      this.mfaDialog = false
    },

    async onSuccess(isBot) {
      const { $store } = this
      await (isBot ? $store.dispatch('botUser/getList') : $store.dispatch('user/getList'))
      this.$emit('input', false)
      if (!isBot) {
        replaceRoute(this.$router, {
          ...this.$route,
          query: { ...R.omit(['changePassword'], this.$route.query) },
        })
      }
    },

    async deleteBot() {
      const { $store, bot: { id: botId } } = this
      this.deleting = true
      try {
        await $store.dispatch('botUser/delete', { botId })
        await this.onSuccess(true)
      } finally {
        this.deleting = false
      }
    },

    // async deleteUser() {
    //   const { $store, user: { id: userId } } = this
    //
    //   const agreed = await $store.dispatch('confirm/openDialog', {
    //     title: this.$t('user.DeleteUserQ'),
    //     subtitle: this.$t('user.UserWillBeDeletedM'),
    //     consentLabel: this.$t('user.Delete'),
    //   })
    //
    //   if (agreed) {
    //     this.saving = true
    //     try {
    //       await $store.dispatch('user/delete', { userId })
    //       await this.onSuccess()
    //     } finally {
    //       this.saving = false
    //     }
    //   }
    // },

    restorePreviousDialog(userId) {
      const { $router, $route } = this

      const query = $route.name === 'Users' ? { ...$route.query } : {}
      query.action = 'edit-user'
      query.userId = userId

      replaceRoute($router, { name: 'Users', query })
    },

    getDefaultForm(isBot = false) {
      const form = {
        userLogin: '',
        role: UserRole.DEFAULT_ROLE,
      }
      if (isBot) {
        form.regenerateToken = false
      } else {
        Object.assign(form, {
          firstName: '',
          lastName: '',
          userEmail: '',
          userPassword: '',
          userPasswordConfirm: '',
          language: 'en',
          state: USER_STATE.ACTIVE.value,
          mfa: false,
          userGroups: [],
        })
      }
      return form
    },

    onGroupSelected(groupId) {
      this.groupSelectorValue = groupId
      this.form.userGroups.push(groupId)
      this.$nextTick(() => {
        this.groupSelectorValue = null
        this.$refs.groupList.$el.scrollIntoView({ block: 'end' })
      })
    },

    getSelectedGroupListeners(groupId) {
      if (!this.currentUser?.isAdmin) return {}
      return { click: () => removeInPlace(this.form.userGroups, groupId) }
    },

    getStateIsDisabled(state) {
      return state.value === USER_STATE.PENDING.value
    },
  },
}
</script>

<style lang="sass">
.UserDialog
  overflow: hidden
  background: white

  &__form
    width: 100%
    align-self: flex-start
    padding-bottom: 64px - 24px

  &__title
    font-size: 24px
    font-weight: 500
    line-height: 32px
    letter-spacing: 0.005em

  &__actions
    display: flex
    padding: 32px 32px 32px 48px
    margin: 0 -48px

  &__hover-icon
    opacity: 0
  &__list-item:hover &__hover-icon,
  &__list-item:focus &__hover-icon
    opacity: 1

  .v-input__icon--prepend-inner .v-icon .v-icon__component
    color: var(--app-prepend-inner-icon-color, var(--v-iconPrimary-base)) !important

  &__subtitle
    //styleName: H5;
    font-size: 16px
    font-weight: 500
    line-height: 24px
    letter-spacing: 0.005em

  &__confirmation-card
    box-shadow: 0 8px 16px 0 #0000001F
    padding: 0 12px
    height: 40px
    white-space: nowrap
    display: flex
    align-items: center
</style>
